import { useState, useEffect, useRef } from 'react';
import { 
	Flex,
	Image,
	Text,
  	ChakraProvider,
	Spinner,
} from "@chakra-ui/react";
import "./App.scss";
import "./mdi/css/materialdesignicons.css";
import Aos from "aos";
import "aos/dist/aos.css";
import logo from "./logo.png"
import whatsapp from "./whatsapp.png"
import message from "./message.png"
import bg from "./bg.jpg"
import cover from "./cover.jpg"
import cover2 from "./cover2.jpg"
import { Accordion, AccordionItem as NewItem } from '@szhsin/react-accordion'
import globalImage from "./global.svg"
import call from "./call.png"
import call2 from "./call2.png"
import call3 from "./call3.png"
import Toast from "./toast"
import favicon from "./favicon.png"

function App() {
	useEffect(() => {
		Aos.init({ duration: 800 })
	}, [])
	const ref1 = useRef("")
	const [loading, setLoading] = useState(false)

	const AccordionItem = ({ header, ...rest }) => (
        <NewItem
          {...rest}
          header={
            <Flex align="center" w="100%" mb={["-2", "0"]} color="#fff">
              <Text className="font" fontSize={["16px", "18px"]} fontWeight="400">{header}</Text>
              <i className='mdi mdi-chevron-down chevron-down' style={{ fontSize: '25px' }}></i>
            </Flex>
          }
        />
    )

	return (
		<ChakraProvider>
			<Flex w="100%" direction="column" fontSize="15px">
				<Flex w="100%" h="30px"></Flex>
				<Flex zIndex="10" bg="rgba(255,255,255,.95)" position="sticky" top="0" w="100%" align="center" px={["5", "10%"]} py="4">
					<Flex flex="1">
						<Image src={logo} h={["20px", "30px"]} />
					</Flex>
					<Flex flex="1" justify="flex-end" align="center">
						{
							["About", "Features", "Benefits"].map((item, index) => (
								<Text display={[index < 2 ? "inline" : "none", "inline"]} key={index} cursor="pointer" mr={["6", "8" ]}_hover={{ fontWeight: "bold", color: "#1ba6ab" }} fontSize={["13px", "15px"]} onClick={() => {
									if(index === 0) {
										const element = document.getElementById('abArea')
										return window.scrollTo({
										top: element ? element.offsetTop - 50 : 0,
										behavior: 'smooth',
										})
									}
									else if(index === 1) {
										const element = document.getElementById('ftArea')
										return window.scrollTo({
										top: element ? element.offsetTop - 50 : 0,
										behavior: 'smooth',
										})
									}
									else if(index === 2) {
										const element = document.getElementById('bnArea')
										return window.scrollTo({
										top: element ? element.offsetTop - 80 : 0,
										behavior: 'smooth',
										})
									}
								}}>{item}</Text>
							))
						}

						{/*<Flex fontSize="18px" w="30px" h="30px" borderRadius="100%" border="2px solid #000" justify="center" align="center" cursor="pointer" mr="6"><i className='mdi mdi-email-outline'></i></Flex>*/}
						
						<Image h={["20px", "30px"]} src={message} mr={["3", "6"]} cursor="pointer" onClick={() => window.open("mailto: info@dtc-trust.com")} />
						<Image display={["inline", "inline"]} h={["20px", "30px"]} src={whatsapp} cursor="pointer" borderRadius="100%" onClick={() => window.open("https://wa.link/1wddvt", "_SELF")} />
					</Flex>
				</Flex>

				<Image onClick={() => window.open("https://wa.link/1wddvt", "_SELF")} boxShadow="rgba(0, 0, 0, 0.08) 0px 1px 10px 4px" position="fixed" bottom="0" right="0" mr="2%" mb="5%" cursor="pointer" zIndex="100" src={whatsapp} h={["35px", "40px"]} />



				<Flex mt="4" h={["84vh", "87vh"]} w="100%" backgroundImage={bg} backgroundRepeat="no-repeat" backgroundSize="cover" backgroundPosition="center center">
					<Flex px={["0", "8%"]} justify={["center", "flex-end"]} align="flex-end" bg="rgba(0,0,0,0.7)">
						<Flex w={["90%", "60%"]} bg={["rgba(255,255,255,.92)", "#fff"]} py="12" px={["5", "12"]} direction="column">
							<Text fontSize={["40px", "50px"]} fontWeight="bold" lineHeight="1.2">
								<Text data-aos="fade-down">Deutsche</Text>
								<Text data-aos="fade-down">Trust Company</Text>
							</Text>
							
							<Text color="slategray" fontSize={["20px", "24px"]} mt="3" data-aos="fade-up">No.1 Trust Company 信託会社</Text>
							<Text fontSize={["13px", "14px"]} textAlign="justify" data-aos="fade-up">We provide investors and stock buyers or purchasers of any goods or services with assurances regarding the quality of the service rendered by the seller to the purchaser, and we employ local agents all over the world to ensure the transparency of transactions.
							</Text>
							<Flex mt="12"><Flex _hover={{ bg: "#013842" }} transition="300ms ease-in-out" px="12" borderRadius="5px" bg="#000" fontWeight="bold" color="#fff" py={["3", "4"]} justify="center" align="center" cursor="pointer" onClick={() => window.open("https://wa.link/1wddvt", "_SELF")}>Contact Us <Text ml="3"><i className='mdi mdi-comment-multiple-outline'></i></Text></Flex></Flex>
						</Flex>
					</Flex>
				</Flex>




				<Flex w="100%" px={["5", "10%"]} direction="column" py={["60px", "80px"]} bg="#fff" color="#000">
					<Text fontSize={["1.5rem", "1.875rem"]} textAlign="center" fontWeight="600" data-aos="fade-down" id="bnArea">Benefits 利点</Text>
					<Text color="#6b7280" mt="2" textAlign="center" px={["2", "15%"]} data-aos="fade-up" fontSize={["13px", "15px"]}>Deutsche Trust Company Limited Japan is a company that helps connect investors and stock companies and traders, unlike traditional Trust companies.</Text>

					<Flex w="100%" mt={["8", "60px"]} justify="space-between" direction={["column", "row"]}>
						<Flex w={["100%", "45%"]}>
							<Flex boxShadow="rgba(0, 0, 0, 0.08) 0px 1px 10px 4px" w="100%" h={["300px", "100%"]} backgroundImage={cover} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center" borderRadius=".5rem"></Flex>
						</Flex>
						<Flex w={["auto", "46%"]} mt={["10", "0"]} direction="column" borderLeft="1px solid #6b7280" ml={["30px", "0"]}>
							{
								[{name: "Assurance of service quality", sub: "for investors and buyers", dp: "mdi-lock-plus"}, {name: "Transparency", sub: "of transactions with the help of local agents worldwide", dp: "mdi-chart-bar"}, {name: "Low fees", sub: "- only 5% of total sales", dp: "mdi-currency-jpy"}, {name: "Swift transactions", sub: "to any country", dp: "mdi-run-fast"}].map((item, index) => (
									<Flex key={index} w="100%" align="flex-start" mb="10" data-aos="fade-up">
										<Flex w="60px" h="60px" justify="center" align="center" borderRadius="100%" border="1px solid rgb(200,200,200)" ml="-30px" bg="#fff" fontSize="30px"><i className={"mdi "+item.dp}></i></Flex>
										<Flex flex="1" ml="5" direction="column">
											<Text mb="1" fontWeight="600">{item.name}</Text>
											<Text color="#6b7280" fontSize="13px">{item.sub}</Text>
										</Flex>
									</Flex>
								))
							}
						</Flex>
					</Flex>
				</Flex>








				<Flex w="100%" px={["5", "8%"]} py={["60px", "80px"]} direction="column" bg="rgb(250,250,255)" color="#000" id="ftArea">
					<Text fontSize={["1.5rem", "1.875rem"]}  textAlign="center" fontWeight="600" data-aos="fade-down">Features 特徴</Text>
					<Text color="#6b7280" mt="2" fontSize={["13px", "15px"]} textAlign="center" px={["2", "15%"]} data-aos="fade-up"> All transactions are predominantly directed to the United States of America, we require only 5% of total sales from our clients, and we guarantee swift transactions to any country.</Text>
					<Flex w="100%" mt={["10", "12"]} justify="space-between" flexWrap="wrap" direction={["column", "row"]}>
						{
							[{name: "Direct communication with clients", sub: "We provide a direct channel of communication between investors, stock companies, and traders, ensuring efficient and effective transactions", dp: "mdi-headset"}, {name: "Global operation", sub: "We operate in any country across the world, enabling investors and buyers from different regions to connect and transact seamlessly", dp: "mdi-web"}, {name: "Cryptocurrency payment acceptance", sub: "Merchants can accept cryptocurrency payments, providing additional flexibility and convenience for buyers and investors", dp: "mdi-currency-btc"}].map((item, index) => (
								<Flex bg="#fff" key={index} w={["100%", "32%"]} px={["5", "8"]} py="8" direction="column" align="center" boxShadow="rgba(0, 0, 0, 0.08) 0px 1px 10px 4px" mb="5" data-aos="fade-up">
									<Flex w='120px' h="120px" borderRadius="100%" border="1px solid #1ba6ab" justify="center" align="center" fontSize="50px">
										<i className={'mdi '+item.dp}></i>
									</Flex>
									<Text textAlign="center" mt="4" fontSize="18px" fontWeight="600">{item.name}</Text>
									<Text textAlign="justify" color="#6b7280" mt="1" fontSize="13px">{item.sub}</Text>
								</Flex>
							))
						}
					</Flex>
				</Flex>


				<Flex w="100%" px={["5", "20%"]} direction="column" py={["100px", "80px"]}>
					<Text fontWeight="bold" fontSize="30px" mb="12" textAlign="center">Quick Actions</Text>
					<Flex align="flex-start" justify="space-between" w="100%">
						<Flex w="28%" align="center" direction="column" data-aos="fade-down">
							<Flex w={["70px", "120px"]} h={["70px", "120px"]} justify="center" align="center" borderRadius="100%" border="1px solid rgb(200,200,200)" bg="#fff" fontSize="60px"><Image h="60px" src={call} /></Flex>
							<Text textAlign="center" fontSize="13px" color="#6b7280" mt="5">Sign up now <Text display={["none", "inline"]}>to experience secure and efficient transactions</Text></Text>
							<Flex mt="6" justify="center" w="100%"><Flex px={["3","6"]} borderRadius="5px" bg="#000" fontWeight="bold" color="#fff" py={["2", "3"]} justify="center" align="center" cursor="pointer" onClick={() => window.open("https://wa.link/1wddvt", "_SELF")} fontSize={["12px", "15px"]}>Proceed <Text ml="3"><i className='mdi mdi-arrow-right'></i></Text></Flex></Flex>
						</Flex>
						<Flex w="28%" align="center" direction="column" data-aos="fade-up">
							<Flex w={["70px", "120px"]} h={["70px", "120px"]} justify="center" align="center" borderRadius="100%" border="1px solid rgb(200,200,200)" bg="#fff" fontSize="60px"><Image h="60px" src={call2} /></Flex>
							<Text textAlign="center" fontSize="13px" color="#6b7280" mt="5">Contact us <Text display={["none", "inline"]}>for more information and personalized assistance</Text></Text>
							<Flex mt="6" justify="center" w="100%"><Flex px={["3", "6"]} borderRadius="5px" bg="#000" fontWeight="bold" color="#fff" py={["2", "3"]} fontSize={["12px", "15px"]} justify="center" align="center" cursor="pointer" onClick={() => window.open("https://wa.link/1wddvt", "_SELF")}>Proceed <Text ml="3"><i className='mdi mdi-arrow-right'></i></Text></Flex></Flex>
						</Flex>
						<Flex w="28%" align="center" direction="column" data-aos="fade-down">
							<Flex w={["70px", "120px"]} h={["70px", "120px"]} justify="center" align="center" borderRadius="100%" border="1px solid rgb(200,200,200)" bg="#fff" fontSize="60px"><Image h="60px" src={call3} /></Flex>
							<Text textAlign="center" fontSize="13px" color="#6b7280" mt="5">Explore <Text display={["none", "inline"]}>our services and fees to make an informed decision</Text></Text>
							<Flex mt="6" justify="center" w="100%"><Flex px={["3", "6"]} borderRadius="5px" bg="#000" fontWeight="bold" color="#fff" py={["2", "3"]} justify="center" align="center" cursor="pointer" onClick={() => window.open("https://wa.link/1wddvt", "_SELF")} fontSize={["12px", "15px"]}>Proceed <Text ml="3"><i className='mdi mdi-arrow-right'></i></Text></Flex></Flex>
						</Flex>
					</Flex>
				</Flex>








				<Flex w="100%" px={["5", "20%"]} direction="column" py={["80px", "100px"]} bg="#cf4302" color="#fff" id="abArea">
					<Flex data-aos="fade-down"><Image src={logo} h="40px" /></Flex>
					<Text fontSize="50px" mt="4" fontWeight="bold" data-aos="fade-up">About Us 私たちについて</Text>
					<Text data-aos="fade-up" mt={["2", "0"]} fontSize={["14px", "15px"]}>Deutsche Trust Company Limited Japan is a company that helps connect investors and stock companies and traders, unlike traditional Trust companies. We provide investors and stock buyers or purchasers of any goods or services with assurances regarding the quality of the service rendered by the seller to the purchaser, and we employ local agents all over the world to ensure the transparency of transactions. All transactions are predominantly directed to the United States of America, we require only 5% of total sales from our clients, and we guarantee swift transactions to any country.</Text>
				</Flex>


				<Flex w="100%" px={["5", "10%"]} direction="column" py={["60px", "80px"]} bg="#fff" color="#000">
					<Flex w="100%" justify="space-between" direction={["column", "row"]}>
						<Flex w={["100%", "45%"]} data-aos="fade-down">
							<Flex w="100%" h={["300px", "100%"]} backgroundImage={cover2} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center" borderRadius=".5rem"></Flex>
						</Flex>
						<Flex data-aos="fade-up" w={["auto", "46%"]} mt={["10", "0"]} py={["0", "60px"]} direction="column">
							<Text fontSize={["34px", "40px"]} fontWeight="bold" lineHeight="1.3">Who Should <Text>Use This Product?</Text></Text>
							<Text color="#6b7280" mt="5" fontSize={["15px", "17px"]}>This product is ideal for investors, stock companies, and traders looking for a reliable and transparent platform to connect and transact. It is also beneficial for merchants who want to expand their payment options by accepting cryptocurrency.</Text>
						</Flex>
					</Flex>
				</Flex>









				<Flex w="100%" px={["5", "12%"]} py={["60px", "80px"]} direction="column" backgroundImage={globalImage} backgroundRepeat="no-repeat" backgroundSize={["120%", "40%"]} bgColor="#013842" backgroundPosition="bottom right">
					<Text fontSize={["1.5rem", "1.875rem"]} fontWeight="700" mt="2" textAlign="center" color="#fff" data-aos="fade-down">Frequently Asked Questions</Text>
					<Text textAlign="center" px={["2", "20%"]} fontSize={["13px", '15px']} color="rgb(240,240,240)" mb="8" data-aos="fade-down">Our extensive network of carriers and partners ensures investors, stock companies, and traders obtain a reliable and transparent platform to connect and transact</Text>
					<Flex direction="column" w="100%" px={["2", "10%"]}>
						<Accordion transition transitionTimeout={1000}>
							<AccordionItem header="How does Deutsche Trust Company Limited ensure the quality of services provided by sellers?">We have strict quality assurance processes in place, and our local agents verify and monitor the service quality of sellers to ensure customer satisfaction
							</AccordionItem>
							<AccordionItem header="Can I use Deutsche Trust Company Limited if I am based in a country outside of the United States?">
							Yes, Deutsche Trust Company Limited operates globally, allowing investors and buyers fromany country to connect and transact.
							</AccordionItem>
							<AccordionItem header="Are there any additional fees apart from the 5% of total sales?">
								No, our fees are transparent and straightforward. We only charge 5%.
							</AccordionItem>
						</Accordion>
					</Flex>
				</Flex>



				
				<Flex w='100%' justify="space-between" pt={["50px", "60px"]} pb={["30px", "60px"]} px={["5", "8%"]} direction={["column", "row"]}>
					<Flex w={["100%", "28%"]}>
						<Flex w="60px" h="60px" backgroundImage={favicon} backgroundRepeat="no-repeat" backgroundSize="100%" backgroundPosition="center center"></Flex>
						<Text ml="6">
							<Text fontWeight="600" fontSize="18px" mb="4">Address</Text>
							<Text color="#6b7280" fontSize="14px" mb="3" cursor="pointer">
								<Text>六本木１丁目９番１０号アークヒルズ仙石山森タワー４０階</Text>
								<Text>港区</Text>
								<Text>1060032</Text>
								<Text>東京都</Text>
								<Text>Japan</Text>
							</Text>
						</Text> 
					</Flex>

					<Flex w={["100%", "32%"]} mt={["8", "0"]} direction="column" display={["none", "flex"]}>
						<Text fontWeight="600" fontSize="18px" mb={["1", "2"]}>Contact</Text>
						<Flex align="center" color="#1ba6ab">
							<Text fontSize="20px"><i className="mdi mdi-email"></i></Text>
							<Text ml="2" cursor="pointer" onClick={() => window.open("mailto: info@dtc-trust.com")} _hover={{ textDecoration: "underline" }}>info@dtc-trust.com</Text>
						</Flex>
						<Text mt="3" color="#6b7280" display={["none", "block"]}>&copy; 2023 Deutsche Trust Company Limited Japan. All Rights Reserved</Text>
					</Flex>

					<Flex w={["100%", "30%"]} mt={["8", "0"]} direction="column">
						<Text fontWeight="600" fontSize={["15px", "18px"]} mb="2">Newsletter</Text>
						<Flex w='100%'><input type="email" style={{ width: "100%", border: "1px solid #1ba6ab", borderRadius: ".5rem", padding: "10px 20px"}} placeholder="Enter your email" ref={ref1} /></Flex>

						<Flex mt="2" w="100%" justify="center" cursor="pointer" borderRadius=".5rem" padding="10px 28px" bg="#1ba6ab" color="#fff" _hover={{ bg: "#48bdb0" }} transition="300ms ease-in-out" fontWeight="500" align="center" onClick={() => {
							if(ref1.current.value !== "") {
								setLoading(true)
								setTimeout(function() {
									Toast("You have been added to Dtc newsletter", "success")
									setLoading(false)
									ref1.current.value = ""
								}, 1500)
							}
							else {
								Toast("Kindly enter your email", "error")
							}
						}}>{loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : <><Text mr="2"><i className="mdi mdi-arrow-right"></i></Text>Subscribe</>}</Flex>
					</Flex>

					<Flex w={["100%", "32%"]} mt={["8", "0"]} direction="column" display={["flex", "none"]}>
						<Text fontWeight="600" fontSize="18px" mb={["1", "2"]}>Contact</Text>
						<Flex align="center" color="#1ba6ab">
							<Text fontSize="20px"><i className="mdi mdi-email"></i></Text>
							<Text ml="2" cursor="pointer" onClick={() => window.open("mailto: info@dtc-trust.com")} _hover={{ textDecoration: "underline" }}>info@dtc-trust.com</Text>
						</Flex>
						<Text mt="3" color="#6b7280" fontSize="13px">&copy; 2023 Deutsche Trust Company Limited Japan. All Rights Reserved</Text>
					</Flex>
				</Flex>

			</Flex>
		</ChakraProvider>
	)
}

export default App;
